import httpClient from './httpClient'

export default {
  DevicePermissionSet: {
    GetDevicePermissionSets(payload) {
      return httpClient.post(
        'DevicePermissionSet/GetDevicePermissionSets',
        payload
      )
    },
    AddDevicePermissionSet(payload) {
      return httpClient.post(
        'DevicePermissionSet/AddDevicePermissionSet',
        payload
      )
    },
    AssignDevicePermission(payload) {
      return httpClient.post(
        'DevicePermissionSet/AssignDevicePermission',
        payload
      )
    },
    DeleteDevicePermissionSet(payload) {
      return httpClient.post(
        'DevicePermissionSet/DeleteDevicePermissionSet',
        payload
      )
    },
    GetDevicePermissionTypes(payload) {
      return httpClient.post(
        'DevicePermissionSet/GetDevicePermissionTypes',
        payload
      )
    },
    GetDevicesForAssigningPermissionSet(payload) {
      return httpClient.post(
        'DevicePermissionSet/GetDevicesForAssigningPermissionSet',
        payload
      )
    },
    UpdateDevicePermissionSet(payload) {
      return httpClient.post(
        'DevicePermissionSet/UpdateDevicePermissionSet',
        payload
      )
    },
  },
}
