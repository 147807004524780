import httpClient from './httpClient'
export default {
  Token: {
    getToken() {
      return httpClient.get('/Token')
    },
    getHasAccess() {
      return httpClient.get('/Token/HasAccess')
    },
    setToken(payload) {
      return httpClient.post('/Token', payload)
    },
    updateTokenById(payload) {
      return httpClient.patch(`/Token/${payload.id}`, payload)
    },
    deleteToken(payload) {
      return httpClient.delete(`/Token/${payload.id}`)
    },
    updateToken(payload) {
      return httpClient.patch(
        `/Token/${payload.id}/status?isActive=${payload.isActive}`
      )
    },
  },
}
