import httpClient from './httpClient'

export default {
  InternalAdmin: {
    GetUsers(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/InternalAdmin/GetUsers', payload)
    },
    GetUsersByCustomer(payload) {
      payload.IncludeAllPages = true
      return httpClient.post('/InternalAdmin/GetUsersByCustomer', payload)
    },
    DeleteCustomerLocation(payload) {
      return httpClient.post('/InternalAdmin/DeleteCustomerLocation', payload)
    },
    DeleteCustomer(payload) {
      return httpClient.post('/InternalAdmin/DeleteCustomer', payload)
    },
    DeleteDealer(payload) {
      return httpClient.post('/InternalAdmin/DeleteDealer', payload)
    },
    DownloadReport(config) {
      return httpClient.get('/InternalAdmin/GetReport', config)
    },
  },
}
