import repository from '@/repository'
import { ActionTree, Module, MutationTree } from 'vuex'
import { RootState } from '../types'

const getDefaultState = (): devicePermissionSetsState => {
  return {
    devicePermissionSetsIsLoading: false,
    locationsDeviceList: [],
    locationsDeviceListIsLoading: false,
    devicePermissionSetList: [],
    devicePermissionTypes: [],
    newPermissonIsLoading: false,
    updatePermissionIsLoading: false,
    updatePermissionErrorMessage: '',
    updatePermissionSuccess: true,
    addPermissionErrorMessage: '',
    addPermissionSuccess: true,
  }
}

const state = getDefaultState()

const mutations: MutationTree<devicePermissionSetsState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setDevicePermissionSetsIsLoading(state, value) {
    state.devicePermissionSetsIsLoading = value
  },
  setLocationsDeviceListIsLoading(state, value) {
    state.locationsDeviceListIsLoading = value
  },
  setDevicePermissionSetList(state, value) {
    state.devicePermissionSetList = value
  },
  setDevicePermissionTypes(state, value) {
    state.devicePermissionTypes = value
  },
  setLocationsDeviceList(state, value) {
    state.locationsDeviceList = value
  },
  setNewPermissonIsLoading(state, value) {
    state.newPermissonIsLoading = value
  },
  setUpdatePermissionIsLoading(state, value) {
    state.updatePermissionIsLoading = value
  },
  setUpdatePermissionErrorMessage(state, value) {
    state.updatePermissionErrorMessage = value
  },
  setUpdatePermissionSuccess(state, value) {
    state.updatePermissionSuccess = value
  },
  setAddPermissionErrorMessage(state, value) {
    state.addPermissionErrorMessage = value
  },
  setAddPermissionSuccess(state, value) {
    state.addPermissionSuccess = value
  },
}

const actions: ActionTree<devicePermissionSetsState, RootState> = {
  getPermissionSets({ commit }, payload) {
    commit('setDevicePermissionSetsIsLoading', true)
    return repository.DevicePermissionSet.GetDevicePermissionSets(payload)
      .then((response) => {
        commit('setDevicePermissionSetList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('setDevicePermissionSetsIsLoading', false)
      })
  },
  async createPermissionSet({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    return repository.DevicePermissionSet.AddDevicePermissionSet(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  assignDevicePermission({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    return repository.DevicePermissionSet.AssignDevicePermission(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  async deleteDevicePermissionSet({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    commit('setUpdatePermissionErrorMessage', '')
    return repository.DevicePermissionSet.DeleteDevicePermissionSet(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  getDevicePermissionTypes({ commit }, payload) {
    return repository.DevicePermissionSet.GetDevicePermissionTypes(payload)
      .then((response) => {
        commit('setDevicePermissionTypes', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
  },
  getDevicesByLocation({ commit }, payload) {
    commit('setLocationsDeviceListIsLoading', true)
    return repository.DevicePermissionSet.GetDevicesForAssigningPermissionSet(
      payload
    )
      .then((response) => {
        commit('setLocationsDeviceList', response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        commit('setLocationsDeviceListIsLoading', false)
      })
  },
  async updateDevicePermissionSet({ commit }, payload) {
    commit('setUpdatePermissionIsLoading', true)
    return repository.DevicePermissionSet.UpdateDevicePermissionSet(payload)
      .then(() => {
        commit('setUpdatePermissionSuccess', true)
      })
      .catch((error) => {
        commit('setUpdatePermissionSuccess', false)
        commit(
          'setUpdatePermissionErrorMessage',
          error.response.data.messageLocalizable
        )
      })
      .finally(() => {
        commit('setUpdatePermissionIsLoading', false)
      })
  },
  clearUpdatePermissionErrorMessage({ commit }) {
    commit('setUpdatePermissionErrorMessage', '')
  },
}

const DevicePermissionSets: Module<devicePermissionSetsState, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
}

export default DevicePermissionSets

interface devicePermissionSetsState {
  devicePermissionSetsIsLoading: boolean
  locationsDeviceList: any[]
  locationsDeviceListIsLoading: boolean
  devicePermissionTypes: any[]
  devicePermissionSetList: any[]
  newPermissonIsLoading: boolean
  updatePermissionIsLoading: boolean
  updatePermissionErrorMessage: string
  updatePermissionSuccess: boolean
  addPermissionErrorMessage: string
  addPermissionSuccess: boolean
}
